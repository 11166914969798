import { motion } from "framer-motion";
import { Check, X, Sparkles } from "lucide-react";

export const ComparisonTable = () => {
    const features = [
        {
            category: "Learning Experience",
            items: [
                {
                    name: "AI-Powered Writing Feedback",
                    telcmaster: true,
                    traditional: false,
                    highlight: true,
                    description:
                        "Get instant, personalized feedback on your writing",
                    explanation:
                        "While traditional courses make you wait days for feedback, GermanPrep provides instant AI-powered corrections, helping you learn from mistakes immediately.",
                },
                {
                    name: "Real Exam Questions",
                    telcmaster: true,
                    traditional: true,
                    description: "Practice with authentic exam materials",
                    explanation:
                        "Both options offer real exam questions, but GermanPrep provides unlimited attempts and instant feedback, unlike traditional courses with limited practice opportunities.",
                },
                {
                    name: "Interactive Exercises",
                    telcmaster: true,
                    traditional: false,
                    description: "Engage with dynamic learning content",
                    explanation:
                        "Traditional courses rely on static worksheets, while GermanPrep offers interactive exercises that adapt to your learning pace and style.",
                },
            ],
        },
        {
            category: "Study Efficiency",
            items: [
                {
                    name: "Spaced Repetition",
                    telcmaster: true,
                    traditional: false,
                    highlight: true,
                    description:
                        "Optimized review intervals for better retention",
                    explanation:
                        "GermanPrep uses AI to schedule reviews at optimal intervals for maximum retention. Traditional courses follow a fixed schedule regardless of your progress.",
                },
                {
                    name: "Progress Tracking",
                    telcmaster: true,
                    traditional: false,
                    description: "Monitor your improvement over time",
                    explanation:
                        "Get detailed analytics and progress insights with GermanPrep, unlike traditional courses where progress tracking is manual and inconsistent.",
                },
                {
                    name: "Personalized Study Path",
                    telcmaster: true,
                    traditional: false,
                    description: "Learning adapted to your needs",
                    explanation:
                        "GermanPrep adapts to your strengths and weaknesses, while traditional courses follow a one-size-fits-all approach that may not suit your learning style.",
                },
            ],
        },
        {
            category: "Cost & Value",
            items: [
                {
                    name: "One-time Payment",
                    telcmaster: true,
                    traditional: false,
                    highlight: true,
                    description: "No recurring fees or hidden costs",
                    explanation:
                        "Traditional courses often require monthly payments or course renewals. GermanPrep offers lifetime access for a single, affordable payment.",
                },
                {
                    name: "Lifetime Access",
                    telcmaster: true,
                    traditional: false,
                    highlight: true,
                    description: "Practice as long as you need",
                    explanation:
                        "With GermanPrep, you can review materials anytime, even after passing your exam. Traditional courses have fixed durations with no ongoing access.",
                },
                {
                    name: "Money-back Guarantee",
                    telcmaster: true,
                    traditional: false,
                    description: "Risk-free investment in your success",
                    explanation:
                        "GermanPrep offers a satisfaction guarantee, while traditional courses rarely provide refunds if you're unhappy with the teaching quality.",
                },
            ],
        },
    ];

    return (
        <div className="overflow-hidden mx-auto w-full max-w-6xl bg-white rounded-xl border border-gray-100 shadow-lg">
            {/* Header - Made responsive */}
            <div className="grid grid-cols-1 gap-4 p-4 bg-gray-50 border-b border-gray-100 md:grid-cols-4 md:p-6">
                <div className="col-span-1 font-medium text-gray-500">
                    Features
                </div>
                <div className="col-span-1 text-center">
                    <motion.div
                        initial={{ opacity: 0, y: 20 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        viewport={{ once: true }}
                        className="relative"
                    >
                        <div className="font-semibold text-gray-900">
                            GermanPrep
                        </div>
                        <div className="hidden absolute -top-12 left-1/2 transform -translate-x-1/2 md:block">
                            <span className="px-3 py-1 text-xs font-medium text-emerald-700 bg-emerald-100 rounded-full">
                                $50 Lifetime Access
                            </span>
                        </div>
                        <div className="flex flex-col items-center mt-2">
                            <span className="text-2xl font-bold text-gray-900">
                                $50
                            </span>
                        </div>
                    </motion.div>
                </div>
                <div className="text-center">
                    <div className="font-medium text-gray-500">
                        Traditional Course
                    </div>
                    <div className="mt-2">
                        <span className="text-2xl font-bold text-gray-900">
                            €500
                        </span>
                        <span className="text-sm text-gray-500">/course</span>
                    </div>
                </div>
                <div className="hidden font-medium text-center text-gray-500 md:block">
                    Why It Matters
                </div>
            </div>

            {/* Features Table - Made responsive */}
            <div className="divide-y divide-gray-100">
                {features.map((category, categoryIndex) => (
                    <motion.div
                        key={category.category}
                        initial={{ opacity: 0, y: 20 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        viewport={{ once: true }}
                        transition={{ delay: categoryIndex * 0.1 }}
                        className="p-4 md:p-6"
                    >
                        <h3 className="mb-6 text-lg font-medium text-gray-900">
                            {category.category}
                        </h3>
                        <div className="space-y-4">
                            {category.items.map((feature, featureIndex) => (
                                <motion.div
                                    key={feature.name}
                                    className={`grid grid-cols-1 md:grid-cols-4 gap-4 p-4 rounded-lg ${
                                        feature.highlight
                                            ? "bg-gray-50 border border-gray-100"
                                            : ""
                                    }`}
                                    initial={{ opacity: 0, x: -20 }}
                                    whileInView={{ opacity: 1, x: 0 }}
                                    viewport={{ once: true }}
                                    transition={{ delay: featureIndex * 0.1 }}
                                >
                                    <div className="col-span-1">
                                        <div className="font-medium text-gray-900">
                                            {feature.name}
                                        </div>
                                        <div className="mt-1 text-sm text-gray-500 md:hidden">
                                            {feature.description}
                                        </div>
                                    </div>
                                    <div className="flex justify-start items-center md:justify-center">
                                        {feature.telcmaster ? (
                                            <motion.div
                                                whileHover={{ scale: 1.2 }}
                                                className="w-5 h-5 text-emerald-500"
                                            >
                                                {feature.highlight ? (
                                                    <Sparkles className="w-5 h-5" />
                                                ) : (
                                                    <Check className="w-5 h-5" />
                                                )}
                                            </motion.div>
                                        ) : (
                                            <X className="w-5 h-5 text-red-400" />
                                        )}
                                    </div>
                                    <div className="flex justify-start items-center md:justify-center">
                                        {feature.traditional ? (
                                            <Check className="w-5 h-5 text-gray-400" />
                                        ) : (
                                            <X className="w-5 h-5 text-red-400" />
                                        )}
                                    </div>
                                    <div className="hidden pl-4 text-sm text-gray-600 border-l border-gray-100 md:block">
                                        {feature.explanation}
                                    </div>
                                </motion.div>
                            ))}
                        </div>
                    </motion.div>
                ))}
            </div>
        </div>
    );
};
